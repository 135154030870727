import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Timeline from "components/Timeline/Timeline.js";

// @material-ui/icons
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import CardTravel from "@material-ui/icons/CardTravel";
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/homeStyle";

import myface from "assets/img/myface.jpeg";

const be_flag = require("assets/img/flags/BE.png");
const fr_flag = require("assets/img/flags/FR.png");
const gb_flag = require("assets/img/flags/GB.png");

const vscode = require("assets/img/vscode.svg");
const react = require("assets/img/react.svg");
const laravel = require("assets/img/laravel.svg");
const gitlab = require("assets/img/gitlab.svg");
const centos = require("assets/img/centos.svg");

const leerkracht = require("assets/img/leerkracht.png");
const videoregie = require("assets/img/videoregie.png");
const lichtregie = require("assets/img/lichtregie.png");

const dnd = require("assets/img/dnd.png");
const games = require("assets/img/games.png");
const piano = require("assets/img/piano.jpeg");

const useStyles = makeStyles(styles);

export default function Home() {
  const classes = useStyles();

  const calculateAge = (birthday) => { // birthday is a date
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={4}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img src={myface} alt="Black-and-white picture of my face" />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Bavo Ceulemans
              </h4>
              <p className={classes.cardProductDesciprion}>
                Twijfelt al lang tussen backend en frontend, is blij dat de term fullstack bestaat :-)
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <p className={classes.cardProductDesciprion}>
                <span>
                  <RoomIcon className={classes.downIcon} /> Lokeren, Oost-Vlaanderen
                </span>
                <br />
                <span>
                  <CakeIcon className={classes.downIcon} /> {calculateAge(Date.parse('15 Jan 1997 01:00:00 GMT'))} jaar
                </span>
                <br />
                <span>
                  <EmailIcon className={classes.downIcon} /> <a href="mailto:contact@bavoceulemans.be">contact@bavoceulemans.be</a>
                </span>
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="warning" text>
              <CardText color="warning">
                <h4 className={classes.cardTitle} style={{color: "white"}}>Mijn huidige stack</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <img src={vscode} alt="VSCode logo" className={classes.stackIcon} style={{height: "40px"}} />
              <img src={react} alt="React logo" className={classes.stackIcon} />
              <img src={laravel} alt="Laravel logo" className={classes.stackIcon} style={{height: "40px"}} />
              <img src={gitlab} alt="GitLab logo" className={classes.stackIcon} style={{height: "52px"}} />
              <img src={centos} alt="CentOS logo" className={classes.stackIcon} />
              <p>
                Mijn IDE is <strong>VSCode</strong> waar ik zowel de frontend <strong>React</strong> als de backend <strong>Laravel</strong>-API in schrijf. Sourcecontrol doe ik op <strong>GitLab</strong> waarop ik met <i>gitlab-runner</i> ook een CI/CD heb lopen die de productiecode pusht naar een <strong>CentOS</strong> host.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={8}>
          <Timeline stories={[
            {
              inverted: true,
              badgeColor: "danger",
              badgeIcon: WorkIcon,
              title: "IT-verantwoordelijke",
              subtitle: "Foubert Icecream, Sint-Niklaas (België)",
              // tag: "",
              // tagColor: "danger",
              body: (
                <p>
                  Hier ben ik verantwoordelijk voor het omvormen van bedrijfseigen software voor horeca en ijskarren naar een lease- en verkoopsklaar product. Tevens plant het bedrijf uit te breiden de komende jaren en is het mijn taak de <i>legacysoftware</i> hierop voor te bereiden en ook mee te denken aan het toekomstplan van het IT-departement van Foubert.
                </p>
              ),
              footerTitle: "01/10/2019 – Heden"
            },
            {
              inverted: false,
              badgeColor: "warning",
              badgeIcon: SchoolIcon,
              title: "Bachelor in de elektronica-ICT",
              subtitle: "Odisee Hogeschool, Gent (België)",
              tag: "MAGNA CUM LAUDE",
              tagColor: "warning",
              body: (
                <p>
                  In deze opleiding "ICT: Web & Mobile" kwamen zowel brede algemene vakken zoals Wiskunde en Ondernemen aan bod als specialisatievakken zoals <strong>OO-Programming</strong> (Java & .NET Core), <strong>Web programming</strong> (HTML/ JS/ CSS & PhP), <strong>UX-/ & UI-design</strong> en -mijn persoonlijke favoriet- <strong>Relationele databanken</strong> (mySQL).
                </p>
              ),
              footerTitle: "01/09/2016 – 02/10/2019"
            },
            {
              inverted: true,
              badgeColor: "danger",
              badgeIcon: WorkIcon,
              title: "(Lead) software developer",
              subtitle: "Foubert Icecream, Sint-Niklaas (België)",
              tag: "Studentenjob",
              tagColor: "danger",
              body: (
                <p>
                  Het grootste project tijdens deze studentenjob was het ontwerpen van een systeem waarbij de 14 ijskarren van Foubert live gevolgd kunnen worden en opgeroepen kunnen worden door een hongerige klant. Verder bouwde ik ook mee aan het intranetplatform en deed ik first- en secondlinesupport voor medewerkers.
                </p>
              ),
              footerTitle: "01/06/2016 – 30/09/2019"
            },
            {
              inverted: false,
              badgeColor: "warning",
              badgeIcon: SchoolIcon,
              title: "Industriële Informatie- & Communicatie Technologie",
              subtitle: "VLOT-VTI Sint-Laurentius, Lokeren (België)",
              // tag: "",
              // tagColor: "warning",
              body: (
                <p>
                  In mijn eerste academische ervaring met <i>hét technische</i> maakte ik kennis met <strong>datacommunicatie</strong>, <strong>electronica & procestechnieken</strong> en een basis <strong>elektriciteit</strong>. Verder kon ik ook mijn reeds bestaande passie voor <strong>programmeren</strong> hier ontwikkelen.
                </p>
              ),
              footerTitle: "01/09/2014 – 31/08/2016"
            },
            {
              inverted: true,
              badgeColor: "warning",
              badgeIcon: SchoolIcon,
              title: "Economie",
              subtitle: "Koninklijk Instituut Woluwe, Sint-Lambrechts-Woluwe (België)",
              // tag: "",
              // tagColor: "warning",
              body: (
                <p>
                  Uit mijn eerste jaren middelbaar onthoud ik vooral de basis <strong>analytische wiskunde</strong>, <strong>geschiedenis</strong> en uiteraard <strong>economie</strong>.
                </p>
              ),
              footerTitle: "01/09/2010 – 31/08/2014"
            },
          ]} />
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="warning" text>
              <CardText color="warning">
                <h4 className={classes.cardTitle} style={{color: "white"}}>In een ander/ vorig leven</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <h5>Wat mij altijd interesseerde maar het toch net niet geworden is...</h5>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <h3>Lesgeven</h3>
                  <img src={leerkracht} alt="Ik tijdens online bijles" className={classes.paragraphImage} />
                  <p>
                    Lesgeven zit zeker in mijn bloed. Mijn moeder, opa, 2 neven en een nicht zitten allemaal in het onderwijs. Hoewel ik mezelf nooit echt voor een klas zag staan, heb ik tijdens en na mijn studies wel enkele keren <strong>bijles gegeven</strong> in o.a. programmeren en relationele databanken.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <h3>Lichtregie</h3>
                  <img src={lichtregie} alt="Ik tijdens online bijles" className={classes.paragraphImage} />
                  <p>
                    Ik kijk met veel plezier terug op de keren dat ik samen met andere studenten <strong>jeugdtoneel</strong> heb mogen maken. Mijn plaats was steevast achter de lichttafel om de spelers (letterlijk) in de spotlight te zetten. Ergens in mijn garage staan nog een paar flightcasen met moving heads en een MQ100 te wachten op de dag dat ik ze terug kan gebruiken.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <h3>Videoregie</h3>
                  <img src={videoregie} alt="Ik tijdens online bijles" className={classes.paragraphImage} />
                  <p>
                    Mijn tofste vakantiejob was ongetwijfeld achter de knoppen zitten in de OB-truck op voornamijk basketwedstrijden. Al zijn er ook enkele optredens, een festival en theatervoorstellingen door mijn vingers gepasseerd.
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="warning" text>
              <CardText color="warning">
                <h4 className={classes.cardTitle} style={{color: "white"}}>In mijn vrije tijd</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <h3>Dungeons & Dragons</h3>
                  <img src={dnd} alt="Mijn dnd kast" className={classes.paragraphImage} />
                  <p>
                    Net als menig nerd voor mij, kan ik mij wel eens volledig storten op een Dungeons & Dragons 5e campaign. Als ik niet de DM ben, is de Tabaxi Rogue mijn go-to PC.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <h3>(Video)games</h3>
                  <img src={games} alt="Mijn spellenkast" className={classes.paragraphImage} />
                  <p>
                    In gezelschap van mijn vrienden hou ik wel van een klassiek borspel als Monopoly, Kolonisten van Catan of Ticket to Ride. Achter de computer kan ik mij volledig verliezen in het optimaliseren van mijn <i>farm</i> op Stardew Valley of mijn stad in Cities: Skylines.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <h3>Piano</h3>
                  <img src={piano} alt="Ik speel piano" className={classes.paragraphImage} />
                  <p>
                    Een virtuoos is aan mij zeker niet verloren gegaan maar als ik in het wild een piano tegenkom durf ik die wel eens bespelen. Ik kan noten lezen maar het liefst speel ik mijn favoriete popnummers tot ik ze vanbuiten kan.
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
