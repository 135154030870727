import Home from "views/Home/Home.js";
// import Home from "views/Resumee/Resumee.js";

// @material-ui/icons
import HomeIcon from "@material-ui/icons/Home";

var dashRoutes = [
  {
    path: "/",
    name: "Home",
    icon: HomeIcon,
    component: Home,
    layout: "/"
  },
];
export default dashRoutes;
